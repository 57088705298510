import {
  Box,
  Grid,
  Paper,
  RadioGroup,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Alert,
  FormControl,
  Radio,
  FormLabel,
  Button,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useCustomSnackBar } from '../../shared/SnackBar/useCustomSnackBar';
import styled from 'styled-components';
import { LoadingButton } from '@mui/lab';
import { lazy, object, SchemaOf, string } from 'yup';
import { Save, Delete } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormSwitch } from '../../shared/FormSwitch/FormSwitch';
import { CustomSelect } from '../../shared/CustomSelect/CustomSelect';
import { CustomTextFieldInput } from '../../shared/CustomTextFieldInput/CustomTextFieldInput';
import { AutocompleteOff, DateTimePickerFormat } from '../CampaignsPage/shared';
import { ProductPriceCard } from './components/ProductPriceCard';
import { calculateProductDefaultPrices } from '../../apis/pricing-api';
import placeholder from '../../assets/img/placeholder-sq.jpeg';
import { FormRadio } from '../../shared/FormRadio/FormRadio';
import { IngredientQuantityChangeType, ProductRecipeGrid } from './components/ProductRecipeGrid';
import { ProductIngredientSearch } from './components/ProductIngredientSearch';
import { isErrorDetails, showValidationErrors } from '../../apis/utils';
import Bottleneck from 'bottleneck';
import { useProductRestoMapping } from '../../apis/restoIntegration-service';
import {
  addProductToDefaultMenu,
  allProductsCacheKey,
  defaultMenuCacheKey,
  getProductPrices,
  isProductInDefaultMenu,
  removeProductFromDefaultMenu,
  singleProductCacheKey,
  updateProductPrice,
  upsertProduct,
  useAllIngredients,
  useAllProductCategories,
  useAllProducts,
  useDefaultProductMenu,
  useProduct,
  useProductCategorySizes,
  useProductSizes,
} from '../../apis/products-api';
import {
  ProblemDetails,
  ProductCategoryDetailsDto,
  ProductImageDto,
  ProductIngredientDto,
  ProductPriceDto,
  ProductPriceModel,
} from '@kotipizzagroup/kotipizza-products-api-client';
import { ProductSizeCard } from './components/ProductSizeCard';
import { ProductPriceCalculationRequest } from '@kotipizzagroup/kotipizza-pricing-api-client';
import { Commerce } from '@kotipizzagroup/kotipizza-shared-types';
import LoadingOverlay from '../../shared/LoadingOverlay/LoadingOverlay';
import { MuiColorInput, matchIsValidColor } from 'mui-color-input';
import { cloneDeep } from 'lodash';
import { SearchInput } from '../../shared/Search/SearchInput';
import ProductLinks from './components/ProductLinks';
import type {
  PriceCalculationProductData,
  ProductDetailsViewParams,
  ProductFormData,
  ProductImagesFormData,
  ProductSizeModel,
} from './types';
import { DateTimePicker } from '../../shared/DateTimePicker/DateTimePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const limiter = new Bottleneck({
  maxConcurrent: 1,
});

const productImagesWithValues = (object: ProductImagesFormData): ProductImagesFormData => {
  const objectsWithValues = cloneDeep(object);
  for (const key in objectsWithValues) {
    const productImage = objectsWithValues[key] as ProductImageDto;
    if (
      !productImage.imageUrl &&
      !productImage.mobileImageUrl &&
      !productImage.menuImageUrl &&
      !productImage.mobileMenuImageUrl
    ) {
      delete objectsWithValues[key]; // Remove empty product image objects, keep only the ones that contains one or more value
    }
  }
  return objectsWithValues;
};

const mapRules = <T,>(map: ProductImagesFormData, rule: T): Record<string, T> => {
  const objectsWithValues = productImagesWithValues(map);
  return Object.keys(objectsWithValues).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {});
};

const dynamicObjectValue: SchemaOf<{
  imageUrl: string;
  mobileImageUrl: string;
  menuImageUrl: string;
  mobileMenuImageUrl: string;
}> = object({
  imageUrl: string().required('Tuotesivu desktop -kuva on pakollinen'),
  mobileImageUrl: string().required('Tuotesivu mobile -kuva on pakollinen'),
  menuImageUrl: string().required('Menusivu desktop -kuva on pakollinen'),
  mobileMenuImageUrl: string().required('Menu-sivun mobile -kuva pakollinen'),
});

const productImagesFormDataSchema = lazy((map) => object(mapRules<typeof dynamicObjectValue>(map, dynamicObjectValue)));
const formSchema: SchemaOf<ProductFormData> = object()
  .shape({
    name: string().required(),
    description: string().nullable(),
    productImagesFormData: productImagesFormDataSchema,
  })
  .defined();

export const ProductDetails: React.FC = () => {
  const [productSizesWithPrice, setProductSizesWithPrice] = useState<ProductSizeModel[]>([]);
  const [imgPath, setImagePath] = useState(placeholder);
  const [productIngredientsRows, setProductIngredientsRows] = useState<ProductIngredientDto[]>([]);
  const [isFetchingPrices, setIsFetchingPrices] = useState(false);
  const [inputPrice, setInputPrice] = useState<Record<number, number>>();
  const [selectedSizeIds, setSelectedSizeIds] = useState<number[]>([]);
  const [isInDefaultMenu, setIsInDefaultMenu] = useState(true);
  const [isScheduled, _setIsScheduled] = useState(false);
  const [backgroundColor, _setBackgroundColor] = useState('');
  const [selectedProductSizeId, setSelectedProductSizeId] = useState<number>();

  const setBackgroundColor = (value: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue(`productImagesFormData.${selectedProductSizeId}.backgroundColor` as any, value);
    _setBackgroundColor(value);
  };

  const setIsScheduled = (value: boolean) => {
    _setIsScheduled(value);
    if (value) {
      setValue('active', true);
    } else {
      setValue('startDate', undefined);
      setValue('endDate', undefined);
    }
  };

  const { id } = useParams<ProductDetailsViewParams>();
  const isNewProduct = isNaN(Number(id));
  const { t } = useTranslation();
  const snackBar = useCustomSnackBar();
  const upsertProductMutation = useMutation(upsertProduct);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const productId = isNewProduct ? 0 : Number(id);

  const {
    data: product,
    isInitialLoading: isInitialLoadingProduct,
    isFetching: isFetchingProduct,
  } = useProduct(productId, { enabled: !isNewProduct });

  const {
    data: products,
    isInitialLoading: isInitialLoadingProducts,
    isFetching: isFetchingProducts,
  } = useAllProducts();

  const {
    data: productRestoMappings,
    isInitialLoading: isInitialLoadingResto,
    isFetching: isFetchingResto,
  } = useProductRestoMapping(productId, !isNewProduct);

  const {
    data: productCategories,
    isInitialLoading: isInitialLoadingCategories,
    isFetching: isFetchingCategories,
  } = useAllProductCategories();

  const {
    data: allIngredients,
    isInitialLoading: isInitialLoadingIngredients,
    isFetching: isFetchingIngredients,
  } = useAllIngredients();

  const {
    data: defaultMenu,
    isInitialLoading: isInitialLoadingDefaultMenu,
    isFetching: isFetchingDefaultMenu,
  } = useDefaultProductMenu();

  const handlePriceChange = (price: number, productSizeId: number) => {
    setInputPrice({ ...inputPrice, [productSizeId]: price });
  };

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    reset,
    setError,
    formState: { errors, isDirty },
  } = useForm<ProductFormData>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      priceModel: ProductPriceModel.PERFETTA,
      active: false,
      productImagesFormData: {},
      startDate: undefined,
      endDate: undefined,
    },
  });

  const categoryId = watch('productCategoryId') || 0;
  const productUrl = watch('url') || '';
  const isActive = watch('active');
  const productImages = watch('productImagesFormData');

  const {
    data: categorySizes,
    isInitialLoading: isInitialLoadingCategorySizes,
    isFetching: isFetchingCategorySizes,
  } = useProductCategorySizes(categoryId);
  const {
    data: productSizes,
    isInitialLoading: isInitialLoadingProductSizes,
    isFetching: isFetchingProductSizes,
  } = useProductSizes(productId, {
    enabled: !isNewProduct,
  });

  const handleSizeToggle = (sizeId: number, checked: boolean) => {
    if (!checked) {
      setSelectedSizeIds(selectedSizeIds.filter((s) => s !== sizeId));
    } else {
      setSelectedSizeIds([...selectedSizeIds, sizeId]);
    }
  };

  const hasSize = selectedSizeIds.length > 0;

  const isInitialLoadComplete =
    !isInitialLoadingProduct &&
    !isInitialLoadingProducts &&
    !isInitialLoadingResto &&
    !isInitialLoadingCategories &&
    !isInitialLoadingIngredients &&
    !isInitialLoadingCategorySizes &&
    !isInitialLoadingProductSizes &&
    !isInitialLoadingDefaultMenu;

  const isFetching =
    isFetchingProduct ||
    isFetchingProducts ||
    isFetchingResto ||
    isFetchingCategories ||
    isFetchingIngredients ||
    isFetchingCategorySizes ||
    isFetchingProductSizes ||
    isFetchingPrices ||
    isFetchingDefaultMenu;

  const isSaveDisabled =
    !isInitialLoadComplete ||
    isFetching ||
    categoryId === 0 ||
    (isActive && !hasSize) ||
    upsertProductMutation.isLoading ||
    product?.isArchived;

  const showProductRecommendation = useMemo(
    () => products && product && product.productCategoryId === Commerce.ProductCategory.PIZZA,
    [product, products]
  );

  const productRecommendationValues = useMemo(() => {
    if (!products) return [];

    return products
      .filter((p) => p.productCategoryId === Commerce.ProductCategory.DIP)
      .map((p) => ({ label: p.name || '', value: p.productId?.toString() || '' }));
  }, [products]);

  const recommendedProductId = watch('productRecommendation.recommendedProductId');
  const selectedProductRecommendation = useMemo(() => {
    if (!products || !recommendedProductId) return '';
    return products.find((p) => p.productId === recommendedProductId)?.name || '';
  }, [products, recommendedProductId]);

  /**
   * EFFECTS
   */

  // Select first of active product sizes for default value for image if selectedProductSizeId is not set
  useEffect(() => {
    if (!selectedProductSizeId && selectedSizeIds[0]) {
      setSelectedProductSizeId(selectedSizeIds[0]);
    }
  }, [selectedProductSizeId, selectedSizeIds]);

  // Set background color and image when product size is changed or set for the first time
  useEffect(() => {
    if (selectedProductSizeId) {
      _setBackgroundColor(productImages?.[selectedProductSizeId]?.backgroundColor || '');
      setImagePath(productImages?.[selectedProductSizeId]?.imageUrl || placeholder);
    }
  }, [productImages, selectedProductSizeId]);

  // Check if product is in default menu
  useEffect(() => {
    if (!defaultMenu?.productIds || isNewProduct) return;

    setIsInDefaultMenu(defaultMenu.productIds.some((pId) => Number(id) === pId));
  }, [defaultMenu, id, isNewProduct]);

  // Update product category name based on category id.
  useEffect(() => {
    if (!isFetchingCategories && productCategories) {
      const category = productCategories.find((c) => c.productCategoryId === categoryId);
      if (category) {
        setValue('productCategoryName', String(category.productCategoryName));
      }
    }
  }, [categoryId, isFetchingCategories, productCategories, setValue]);

  // Fill in initial product sizes.
  useEffect(() => {
    if (!isFetchingProductSizes && productSizes && isInitialLoadComplete) {
      setSelectedSizeIds(productSizes.map((s) => s.productSizeId));
    }
  }, [isFetchingProductSizes, isInitialLoadComplete, productSizes]);

  // Fill in initial data from product.
  useEffect(() => {
    if (isFetchingProduct || !product || !isInitialLoadComplete) return;

    setProductIngredientsRows(product.ingredients || []);

    const productImagesFormData: ProductImagesFormData = {};

    product.productImages?.forEach((image) => {
      if (image.productSizeId) {
        productImagesFormData[image.productSizeId] = image;
      }
    });

    let startDate = undefined;
    let endDate = undefined;

    if (product.startDate) {
      startDate = dayjs(product.startDate).local().format(DateTimePickerFormat);
    }

    if (product.endDate) {
      endDate = dayjs(product.endDate).local().format(DateTimePickerFormat);
    }

    _setIsScheduled(Boolean(product.startDate || product.endDate));

    reset({ ...product, productImagesFormData, startDate, endDate });
  }, [isFetchingProduct, isInitialLoadComplete, product, reset]);

  // Remove selected size ids if category is changed.
  useEffect(() => {
    const categorySizeIds = categorySizes?.map((cs) => cs.productSizeId);
    const previouslySelected = selectedSizeIds.filter((id) => !categorySizeIds?.includes(id));
    if (previouslySelected.length > 0) {
      setSelectedSizeIds([]);
    }
  }, [categorySizes, selectedSizeIds]);

  useEffect(() => {
    const subscription = watch((value) => {
      if (selectedProductSizeId) {
        setImagePath(value.productImagesFormData?.[selectedProductSizeId]?.imageUrl || placeholder);
      }
    });
    return () => subscription.unsubscribe();
  }, [selectedProductSizeId, watch]);

  useEffect(() => {
    if (product) {
      const category = productCategories?.find((x) => x.productCategoryId == product.productCategoryId);
      if (category) {
        setProductSizesWithPrice(
          category.productSizes?.map((ps) => {
            const restoIdStatus =
              (productRestoMappings?.find((x) => x.sizeId == ps.productSizeId) || {}).restoArticleId ??
              t('product.restoMapping.missing') ??
              '';
            return {
              sizeId: ps.productSizeId || 0,
              sizeName: ps.name || '',
              restoIdStatus,
              calculatedPrice: 0,
              minimumPrice: 0,
              price: product.price || null,
            };
          }) || []
        );
      }
    }
  }, [product, productRestoMappings, productCategories, reset, t]);

  const setCalculatedSizePrice = useCallback(
    (data: Pick<ProductSizeModel, 'sizeId' | 'calculatedPrice' | 'minimumPrice' | 'price' | 'sizeName'>) => {
      setProductSizesWithPrice((ps) => {
        const sizeIds = ps.map((m) => m.sizeId);
        // In case of creating new product we might need to push the value instead of just updating prices.
        if (isNewProduct && !sizeIds.includes(data.sizeId)) {
          ps.push({
            calculatedPrice: data.calculatedPrice,
            minimumPrice: data.minimumPrice,
            price: data.price,
            sizeId: data.sizeId,
            sizeName: data.sizeName,
            restoIdStatus: '',
          });
        }
        return ps.map((item) => {
          if (item.sizeId === data.sizeId) {
            return {
              ...item,
              calculatedPrice: data.calculatedPrice,
              minimumPrice: data.minimumPrice,
              price: data.price,
            };
          }
          return item;
        });
      });
    },
    [isNewProduct]
  );

  useEffect(() => {
    const fetchPrices = async () => {
      const { name, productCategoryId, productCategoryName } = getValues();
      if (
        isInitialLoadComplete &&
        name &&
        productCategoryId &&
        productCategoryName &&
        selectedSizeIds.length > 0 &&
        categorySizes
      ) {
        setIsFetchingPrices(true);
        // In case of creating new product we can use bare minimum DTO data for price calculation.
        const productData: PriceCalculationProductData = product || { name, productCategoryId, productCategoryName };
        // Get current prices in case we have existing product.
        const prices = productId ? await getProductPrices(productId) : [];

        const enabledSizes = categorySizes.filter((s) => selectedSizeIds.includes(s.productSizeId));

        const defaultPrices = await calculateProductDefaultPrices({
          product: { ...productData, ingredients: productIngredientsRows },
          ingredients: allIngredients || [],
          productSizes: enabledSizes,
        } as ProductPriceCalculationRequest);

        enabledSizes.forEach((enabledSize) => {
          const sizeId = enabledSize.productSizeId;
          const sizeName = enabledSize.name;
          if (!sizeId || !sizeName) return;
          const sizeDefaultPrice = defaultPrices.find((p) => p.productSizeID === sizeId);
          const sizePrice = prices.find((p) => p.productSizeId === sizeId);

          setCalculatedSizePrice({
            sizeId,
            sizeName,
            calculatedPrice: sizeDefaultPrice?.calculatedPrice || 0,
            minimumPrice: sizePrice?.minimumPrice || 0,
            price: sizePrice?.price || 0,
          });
        });

        setIsFetchingPrices(false);
      }
    };

    limiter.schedule(() => {
      return fetchPrices();
    });
  }, [
    allIngredients,
    categorySizes,
    getValues,
    isInitialLoadComplete,
    isNewProduct,
    product,
    productId,
    productIngredientsRows,
    selectedSizeIds,
    setCalculatedSizePrice,
  ]);

  const handleIngredientQuantityChange = (ingredientId: number, changeType: IngredientQuantityChangeType) => {
    setProductIngredientsRows((currentRows) =>
      currentRows
        ?.map((row) => {
          if (row.ingredientId === ingredientId) {
            return {
              ...row,
              quantity:
                changeType === IngredientQuantityChangeType.ADD
                  ? (row.quantity as number) + 1
                  : (row.quantity as number) - 1,
            };
          }
          return { ...row };
        })
        .filter((x) => x.quantity)
    );
  };

  const newProductIngredient = (productId: number, ingredientId: number): ProductIngredientDto => {
    return {
      ingredientId,
      maxQuantity: 3,
      minQuantity: 0,
      productId: productId,
      quantity: 1,
    };
  };

  const handleAddIngredient = (ingredientId: number) => {
    if (productIngredientsRows?.find((x) => x.ingredientId === ingredientId)) {
      snackBar.showError('Ingredient already part of the product recipe');
      return;
    }

    const productId = product?.productId || 0; // 0 is used for new products
    const newIngredient = newProductIngredient(productId, ingredientId);
    setProductIngredientsRows((currentRows) => currentRows && [...currentRows, newIngredient]);
  };

  const handleMaxQuantityChange = (ingredientId: number, value: string) => {
    setProductIngredientsRows((currentRows) => {
      return currentRows?.map((row) => {
        if (row.ingredientId === ingredientId) {
          return { ...row, maxQuantity: +value };
        }
        return row;
      });
    });
  };

  const handleIngredientRequiredChange = (ingredientId: number, value: boolean) => {
    setProductIngredientsRows((currentRows) => {
      return currentRows?.map((row) => {
        if (row.ingredientId === ingredientId) {
          return { ...row, minQuantity: Number(value) };
        }
        return row;
      });
    });
  };

  const getSavedProductIngredients = (savedProduct: ProductFormData, ingredients: ProductIngredientDto[]) => {
    if (!ingredients) return [];

    if (savedProduct.productCategoryId !== Commerce.ProductCategory.ETUPIZZA || savedProduct.productId) {
      return ingredients;
    }

    // Add min/max configuration for Etupizza ingredients
    return ingredients.map((ingredient) => ({
      ...ingredient,
      maxQuantity: ingredient.quantity,
      minQuantity: ingredient.quantity,
    }));
  };

  const getProductImages = (productImagesFormData: ProductImagesFormData): ProductImageDto[] => {
    const productImages = productImagesWithValues(productImagesFormData);
    return Object.entries(productImages).map(([key, value]) => {
      return { ...value, productSizeId: Number(key) };
    });
  };

  const handleProductSave: SubmitHandler<ProductFormData> = async (formData) => {
    try {
      const payload: ProductFormData = {
        ...formData,
        ingredients: [...getSavedProductIngredients(formData, productIngredientsRows)],
        productImagesFormData: {}, //no need to send this
        productImages: [...getProductImages(formData.productImagesFormData)],
        // Make sure not to send empty recommendation.
        productRecommendation:
          formData.productRecommendation?.recommendationText && formData.productRecommendation.recommendedProductId
            ? formData.productRecommendation
            : null,
      };

      payload.startDate = payload.startDate ? dayjs(payload.startDate).utc().format() : undefined;
      payload.endDate = payload.endDate ? dayjs(payload.endDate).utc().format() : undefined;

      if (backgroundColor.length > 0) {
        payload.backgroundColor = backgroundColor;
      }

      // Set pricing model.
      if (payload.priceModel === ProductPriceModel.PERFETTA) {
        payload.hasMinimumPrice = false;
        payload.hasFixedMinimumPrice = false;
      }
      if (payload.priceModel === ProductPriceModel.FIXED_MIN_PRICE) {
        payload.hasMinimumPrice = true;
        payload.hasFixedMinimumPrice = true;
      }
      if (payload.priceModel === ProductPriceModel.MONSTER) {
        payload.hasMinimumPrice = true;
        payload.hasFixedMinimumPrice = false;
      }

      if (isNewProduct) {
        // slug is generated from product name in Products API but required
        // field so let's send an empty string in case creating new product.
        payload.slug = '';
      }

      // Save product
      const response = await upsertProductMutation.mutateAsync(payload);

      const enabledPrices = productSizesWithPrice.filter((item) => selectedSizeIds.includes(item.sizeId));

      if (response.id) {
        const productId = response.id;

        // Save product prices
        const prices: ProductPriceDto[] = enabledPrices.map((item) => {
          // Initial price is either the previously saved mininum price or price calculated for the product size.
          const initialPrice = item.minimumPrice || item.calculatedPrice;

          // Fixed price is something that the user has typed into the price inputs or initial price if fields are untouched.
          const fixedPrice = inputPrice && inputPrice[item.sizeId] ? inputPrice[item.sizeId] : initialPrice;

          const hasEditablePrice =
            payload.priceModel === ProductPriceModel.FIXED_MIN_PRICE ||
            payload.priceModel === ProductPriceModel.MONSTER;

          // This is the value that we save as product sizes minimum price.
          const minimumPrice = hasEditablePrice ? fixedPrice : item.calculatedPrice;

          return {
            productId,
            productSizeId: item.sizeId,
            priceCorrection: 0,
            minimumPrice,
            price: null, // this is not saved anywhere so we can pass null.
          };
        });

        await updateProductPrice(productId, prices);

        // Handle product menus for product
        const isIncludedInDefaultMenu = await isProductInDefaultMenu(response.id);

        if (isInDefaultMenu && !isIncludedInDefaultMenu) {
          addProductToDefaultMenu(response.id);
        } else if (!isInDefaultMenu && isIncludedInDefaultMenu) {
          removeProductFromDefaultMenu(response.id);
        }
      }

      await queryClient.invalidateQueries(allProductsCacheKey);
      await queryClient.invalidateQueries(['product-sizes', payload.productId]);
      await queryClient.invalidateQueries(['product-prices', payload.productId]);
      await queryClient.invalidateQueries(['product-category-sizes', payload.productCategoryId]);
      await queryClient.invalidateQueries([defaultMenuCacheKey]);

      if (!payload.productId) {
        snackBar.showSuccess('New product created');
        navigate(`/products/${response.id}`);
      } else {
        snackBar.showSuccess('Product updated');
        await queryClient.invalidateQueries(singleProductCacheKey(payload.productId));
      }
    } catch (e) {
      const details = e as ProblemDetails;
      if (isErrorDetails(details)) {
        showValidationErrors<ProductFormData>(details.errors, setError, t);
      }
      snackBar.showError(t(details?.title || 'Could not save product'));
    }
  };

  const SaveButton = () => {
    return (
      <LoadingButton
        onClick={handleSubmit(handleProductSave)}
        startIcon={<Save />}
        loading={upsertProductMutation.isLoading}
        disabled={isSaveDisabled}
        variant="contained"
        color="primary"
      >
        {product?.isArchived ? t('products.archivedNoEditing') : t('global.save')}
      </LoadingButton>
    );
  };

  const pageModeTitle = useMemo(() => {
    if (product?.isArchived) return t('products.archivedNoEditing');
    return isNewProduct ? t('product.addProduct') : t('product.editProduct');
  }, [isNewProduct, product?.isArchived, t]);

  return (
    <Box>
      {!isInitialLoadComplete ? <LoadingOverlay /> : null}
      <TopBar>
        <Typography variant="h1">
          {t('product.title')} / {pageModeTitle}
        </Typography>
        <SaveButton />
      </TopBar>
      <Box display="flex">
        <Grid width="100%" display="flex" container spacing={3} justifyContent="flex-start">
          <Grid item xs={12} md={3}>
            <CustomSelect
              name="productCategoryId"
              control={control}
              label={t('productDetails.productCategoryLabel')}
              emptyText="Valitse tuoteryhmä"
              showEmptyOption={false}
              isLoading={isFetchingCategories}
              isDisabled={product?.isArchived}
              options={
                productCategories?.map((cat: ProductCategoryDetailsDto) => ({
                  title: cat.productCategoryName,
                  value: cat.productCategoryId,
                })) || []
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormSwitch
              name="active"
              control={control}
              label={t('productDetails.active')}
              uncheckedLabel={t('productDetails.inactive')}
              disabled={product?.isArchived}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControlLabel
              control={<Switch checked={isInDefaultMenu} onChange={(e) => setIsInDefaultMenu(e.target.checked)} />}
              label={t('productDetails.isInDefaultMenu')}
              disabled={product?.isArchived}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControlLabel
              control={<Switch checked={isScheduled} onChange={(e) => setIsScheduled(e.target.checked)} />}
              label={t('productDetails.isScheduled')}
              disabled={product?.isArchived}
            />
          </Grid>
          {!isInDefaultMenu && (
            <Grid item xs={12}>
              <Alert severity="warning">{t('productDetails.defaultMenuInfo')}</Alert>
            </Grid>
          )}
          {isScheduled && !isActive && (
            <Grid item xs={12}>
              <Alert severity="warning">{t('productDetails.productSchedulingInfo')}</Alert>
            </Grid>
          )}
          {isScheduled && (
            <>
              <Grid item xs={12} md={6}>
                <DateTimePicker
                  name="startDate"
                  control={control}
                  label={t('global.start')}
                  error={errors['startDate']}
                  isDirty={isDirty}
                  defaultValue={undefined}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DateTimePicker
                  name="endDate"
                  control={control}
                  label={t('global.end')}
                  error={errors['endDate']}
                  isDirty={isDirty}
                  defaultValue={undefined}
                />
              </Grid>
            </>
          )}
          <Box width="100%" />
          <Grid item xs={12}>
            <Typography variant="h2">{t('productDetails.productInformation')}</Typography>
            <CustomTextFieldInput
              name="name"
              label={t('productDetails.productNameLabel')}
              control={control}
              isDirty={isDirty}
              error={errors['name']}
              textFieldProps={{
                inputProps: AutocompleteOff,
                rows: 1,
                multiline: true,
                margin: 'normal',
              }}
            />
            <TextField
              label={t('productDetails.productSlugLabel')}
              value={productUrl}
              margin="normal"
              size="small"
              inputProps={{
                readOnly: true,
              }}
            />
            <CustomTextFieldInput
              name="description"
              label={t('productDetails.productDescriptionLabel')}
              control={control}
              isDirty={isDirty}
              error={errors['description']}
              textFieldProps={{
                inputProps: AutocompleteOff,
                rows: 7,
                multiline: true,
                margin: 'normal',
              }}
            />
            <CustomTextFieldInput
              name="menuCardDescription"
              label={t('productDetails.productMenuCardDescriptionLabel')}
              control={control}
              isDirty={isDirty}
              error={errors['menuCardDescription']}
              textFieldProps={{
                inputProps: AutocompleteOff,
                rows: 4,
                multiline: true,
                margin: 'normal',
              }}
            />
            <small>{t('productDetails.productMenuCardDescriptionHint')}</small>
          </Grid>
          {selectedProductSizeId && (
            <>
              <Grid container item justifyContent="space-between" alignItems="center" direction="row">
                <Typography variant="h2">Kuvat</Typography>
                {productSizesWithPrice.length > 1 &&
                  product &&
                  [Commerce.ProductCategory.PIZZA, Commerce.ProductCategory.MONSTER].includes(
                    product.productCategoryId
                  ) && (
                    <FormControl component="fieldset">
                      <FormLabel>
                        <b>Kuvat koolle:</b>
                      </FormLabel>
                      <RadioGroup
                        row
                        value={selectedProductSizeId}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setSelectedProductSizeId(+event.target.value);
                        }}
                      >
                        <>
                          {productSizesWithPrice.map((model) => {
                            const containsError = errors.productImagesFormData?.[model.sizeId];
                            return (
                              <FormControlLabel
                                key={'radio' + model.sizeId}
                                value={model.sizeId}
                                control={<Radio />}
                                label={<span style={containsError && { color: 'red' }}>{model.sizeName}</span>}
                              />
                            );
                          })}
                        </>
                      </RadioGroup>
                    </FormControl>
                  )}
              </Grid>
              <ImageContainer sx={{ backgroundColor }}>
                <img src={imgPath} alt="Product picture" />
              </ImageContainer>
              <Grid padding={0} item xs={12} md={8}>
                <CustomTextFieldInput
                  key={`productImagesFormData.${selectedProductSizeId}.imageUrl`}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  name={`productImagesFormData.${selectedProductSizeId}.imageUrl` as any}
                  label={t('productDetails.imagepathLabel')}
                  control={control}
                  isDirty={isDirty}
                  error={errors.productImagesFormData?.[selectedProductSizeId]?.imageUrl}
                  textFieldProps={{
                    onClick: () => {
                      const imageUrl = productImages?.[selectedProductSizeId]?.imageUrl || null;
                      if (imageUrl) {
                        setImagePath(imageUrl);
                      }
                    },
                    inputProps: AutocompleteOff,
                    rows: 1,
                    multiline: true,
                    margin: 'normal',
                  }}
                />
                <CustomTextFieldInput
                  key={`productImagesFormData.${selectedProductSizeId}.mobileImageUrl`}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  name={`productImagesFormData.${selectedProductSizeId}.mobileImageUrl` as any}
                  label={t('productDetails.imagepathMobileLabel')}
                  control={control}
                  isDirty={isDirty}
                  error={errors.productImagesFormData?.[selectedProductSizeId]?.mobileImageUrl}
                  textFieldProps={{
                    onClick: () => {
                      const mobileImageUrl = productImages?.[selectedProductSizeId]?.mobileImageUrl || null;
                      if (mobileImageUrl) {
                        setImagePath(mobileImageUrl);
                      }
                    },
                    inputProps: AutocompleteOff,
                    rows: 1,
                    multiline: true,
                    margin: 'normal',
                  }}
                />
                <CustomTextFieldInput
                  key={`productImagesFormData.${selectedProductSizeId}.menuImageUrl`}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  name={`productImagesFormData.${selectedProductSizeId}.menuImageUrl` as any}
                  label={t('productDetails.menuImagepathLabel')}
                  control={control}
                  isDirty={isDirty}
                  error={errors.productImagesFormData?.[selectedProductSizeId]?.menuImageUrl}
                  textFieldProps={{
                    onClick: () => {
                      const menuImageUrl = productImages?.[selectedProductSizeId]?.menuImageUrl || null;
                      if (menuImageUrl) {
                        setImagePath(menuImageUrl);
                      }
                    },
                    inputProps: AutocompleteOff,
                    rows: 1,
                    multiline: true,
                    margin: 'normal',
                  }}
                />
                <CustomTextFieldInput
                  key={`productImagesFormData.${selectedProductSizeId}.mobileMenuImageUrl`}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  name={`productImagesFormData.${selectedProductSizeId}.mobileMenuImageUrl` as any}
                  label={t('productDetails.menuImagepathMobileLabel')}
                  control={control}
                  isDirty={isDirty}
                  error={errors.productImagesFormData?.[selectedProductSizeId]?.mobileMenuImageUrl}
                  textFieldProps={{
                    onClick: () => {
                      const mobileMenuImageUrl = productImages?.[selectedProductSizeId]?.mobileMenuImageUrl || null;
                      if (mobileMenuImageUrl) {
                        setImagePath(mobileMenuImageUrl);
                      }
                    },
                    inputProps: AutocompleteOff,
                    rows: 1,
                    multiline: true,
                    margin: 'normal',
                  }}
                />
                {product &&
                  [Commerce.ProductCategory.DIP, Commerce.ProductCategory.DRINK].includes(
                    product.productCategoryId
                  ) && (
                    <MuiColorInput
                      margin="normal"
                      label={t('productDetails.backgroundColor')}
                      format="hex"
                      key={`productImagesFormData.${selectedProductSizeId}.backgroundColor`}
                      value={productImages?.[selectedProductSizeId]?.backgroundColor || ''}
                      onChange={(value) => {
                        if (matchIsValidColor(value)) {
                          setBackgroundColor(value);
                        } else {
                          setBackgroundColor('');
                        }
                      }}
                      isAlphaHidden
                    />
                  )}
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography variant="h2">Hinnoittelumalli</Typography>
            <Box width="100%" paddingTop="1.5em" />
            <Paper>
              <RadioGroup row name="priceModel">
                <Grid item xs={12} lg={3} md={4} padding="1.5em">
                  <FormRadio
                    name="priceModel"
                    value={ProductPriceModel.PERFETTA}
                    control={control}
                    label={<Typography variant="h3">1. Hinta täytteiden mukaan</Typography>}
                  />

                  <ul>
                    <Typography variant="body2">
                      <li>Hinta määräytyy täytteiden mukaan.</li>
                      <li>Täytteen poistaminen laskee hintaa.</li>
                      <li>Esim. listapizzat</li>
                    </Typography>
                  </ul>
                </Grid>

                <Grid item xs={12} lg={3} md={4} padding="1.5em">
                  <FormRadio
                    name="priceModel"
                    value={ProductPriceModel.FIXED_MIN_PRICE}
                    control={control}
                    label={<Typography variant="h3">2. Lukittu minimihinta</Typography>}
                  />

                  <ul>
                    <Typography variant="body2">
                      <li>Tuoteen minimihinta ei riipu täytteiden määrästä.</li>
                      <li>Täytteiden poistaminen ei laske minimihintaa.</li>
                      <li>Esim. kausipizzat</li>
                    </Typography>
                  </ul>
                </Grid>

                <Grid item xs={12} lg={3} md={4} padding="1.5em">
                  <FormRadio
                    name="priceModel"
                    value={ProductPriceModel.MONSTER}
                    control={control}
                    label={<Typography variant="h3">3. Monster-malli</Typography>}
                  />

                  <ul>
                    <Typography variant="body2">
                      <li>Tuoteella on minimihinta, johon sisältyy täytteitä tietyllä euromäärällä.</li>
                      <li>poistaminen ei laske minimihintaa.</li>
                      <li>Esim. Monsterit</li>
                    </Typography>
                  </ul>
                </Grid>
              </RadioGroup>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2">Koko</Typography>
          </Grid>
          {categorySizes?.map((size) => (
            <Grid key={size.productSizeId} item xs={12} md={4} lg={3}>
              <ProductSizeCard
                title={String(size.name)}
                sizeId={size.productSizeId}
                onChange={handleSizeToggle}
                isLoading={isFetchingCategorySizes || isFetchingProductSizes || isFetchingPrices}
                selected={selectedSizeIds.includes(size.productSizeId)}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Typography variant="h2">Hinta</Typography>
          </Grid>
          {selectedSizeIds.map((selectedSizeId) => {
            const model = productSizesWithPrice.find((item) => item.sizeId === selectedSizeId);
            const priceModel = watch('priceModel') || ProductPriceModel.PERFETTA;
            if (!model) return null;
            return (
              <Grid key={model.sizeId} item xs={12} md={4} lg={3}>
                <ProductPriceCard
                  productSizeId={model.sizeId}
                  isLoadingPrice={isFetchingPrices}
                  calculatedPrice={model.calculatedPrice || 0}
                  price={
                    priceModel === ProductPriceModel.FIXED_MIN_PRICE || priceModel === ProductPriceModel.MONSTER
                      ? model.minimumPrice || model.calculatedPrice
                      : model.calculatedPrice
                  }
                  title={model.sizeName}
                  priceModel={priceModel}
                  handleChange={handlePriceChange}
                />
              </Grid>
            );
          })}
          <Grid container item justifyContent="space-between" alignItems="center">
            <Grid item xs={12} md={4}>
              <Typography variant="h2">Resepti ja annostukset</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <ProductIngredientSearch onIngredientSelect={handleAddIngredient} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ProductRecipeGrid
              productId={Number(id || 0)}
              productCategoryId={categoryId}
              productIngredientsRows={productIngredientsRows || []}
              onIngredientQuantityChange={handleIngredientQuantityChange}
              onMaxQuantityChange={handleMaxQuantityChange}
              onIngredientRequiredChange={handleIngredientRequiredChange}
            />
          </Grid>
          {showProductRecommendation && (
            <Grid container item justifyContent="space-between" alignItems="center">
              <Grid item xs={12} my={2}>
                <Typography variant="h2">{t('productDetails.productRecommendations')}</Typography>
              </Grid>
              <Grid item xs={12} md={5} my={2}>
                <SearchInput
                  onSelect={(value) => setValue('productRecommendation.recommendedProductId', Number(value))}
                  values={productRecommendationValues}
                  label={t('productDetails.recommendedProduct')}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <TextField
                  value={selectedProductRecommendation}
                  margin="normal"
                  size="small"
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} my={2}>
                <CustomTextFieldInput
                  name="productRecommendation.recommendationText"
                  label={t('productDetails.recommendationText')}
                  control={control}
                  isDirty={isDirty}
                  error={errors['productRecommendation']}
                  textFieldProps={{
                    inputProps: AutocompleteOff,
                    rows: 2,
                    multiline: true,
                    margin: 'normal',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    setValue('productRecommendation.recommendationText', undefined);
                    setValue('productRecommendation.recommendedProductId', undefined);
                    setValue('productRecommendation', null);
                  }}
                  startIcon={<Delete />}
                  disabled={upsertProductMutation.isLoading}
                  color="error"
                >
                  {t('global.delete')} {t('productDetails.recommendedProduct')}
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h2">Linkitykset</Typography>
          </Grid>
          <Grid item xs={12}>
            <ProductLinks productId={productId} />
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Box>
      <Grid item xs={12} container justifyContent="space-between">
        <SaveButton />
      </Grid>
      <BottomBar />
    </Box>
  );
};

export const BottomBar = styled.div`
  height: 100px;
  width: 100%;
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;

const ImageContainer = styled(Grid)`
  height: 396px;
  width: 396px;
  margin: 20px;
  border: solid darkgray 1px;
  border-radius: 4px;

  img {
    max-width: 392px;
    max-height: 392px;
  }
`;
